@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;700&display=swap");

:root {
  --hiburim-s-completedRowWidth: 100%;
  --hiburim-s-completedRowHeight: 60vh;
  --hiburim-s-wordWidth: 22vw;
  --hiburim-s-wordHeight: 10vh;
  --hiburim-s-viewWidth: 90vw;
  --hiburim-s-mistakesRemaining: 40%;
  --hiburim-s-modalWidth: 85vw;
  --hiburim-s-menuWidth: 100%;
}
@media (min-width: 600px) {
  :root {
    --hiburim-s-completedRowWidth: 100%;
    --hiburim-s-completedRowHeight: 240px;
    --hiburim-s-wordWidth: 100px;
    --hiburim-s-wordHeight: 40px;
    --hiburim-s-viewWidth: 544px;
    --hiburim-s-mistakesRemaining: 50%;
    --hiburim-s-modalWidth: 600px;
    --hiburim-s-menuWidth: max(50vw, 600px);
  }
}
@media (min-width: 1024px) {
  :root {
    --hiburim-s-completedRowWidth: 100%;
    --hiburim-s-completedRowHeight: 300px;
    --hiburim-s-wordWidth: 130px;
    --hiburim-s-wordHeight: 90px;
    --hiburim-s-viewWidth: 544px;
    --hiburim-s-mistakesRemaining: 50%;
    --hiburim-s-modalWidth: 600px;
    --hiburim-s-menuWidth: max(50vw, 600px);
  }
}

.headerLogo {
  position: relative;
  aspect-ratio: 521 / 98;
  width: 90vw;
}
@media (min-width: 600px) {
  .headerLogo {
    width: 544px;
  }
}
@media (min-width: 1024px) {
  .headerLogo {
    width: 544px;
  }
}

.selected {
  background-color: #f5f5f5;
}
.unselected {
  background-color: #fff;
}
.shake {
  animation: shake 0.15s;
}

body {
  margin: 0;
}

@keyframes shake {
  0%,
  100% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(-10px);
  }
  75% {
    transform: translateX(10px);
  }
}

@keyframes popIn {
  0% {
    transform: scale(0.9);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}
#container-519180bceb6f4e04326f48e3b3d26116 {
  max-width: 90%;
  margin: auto;
  position: absolute;
  overflow: scroll;
  z-index: 1000;
  background-color: #fff;
  top: 10px;
  left: 5%;
  right: 5%;
  border: 1px solid;
  padding: 0;
}

.canvasFullscreen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: -1;
}

@media (min-width: 1024px) {
  .navbar {
    display: flex;
    margin-top: 0;
    border-top: 0;
    margin-bottom: 0;
    width: var(--hiburim-s-menuWidth);
    background-color: var(--hiburim-menu);
  }
}

@media (max-width: 1024px) {
  .navbar {
    display: flex;
    border-top: 0;
    width: var(--hiburim-s-menuWidth);
    background-color: var(--hiburim-menu);
    align-items: right;
    padding: 0;
    border-top: "1px solid #e0e0e0";
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1000;
  }
}

