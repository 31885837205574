@media (min-width: 1024px) {
  .navbar {
    display: flex;
    margin-top: 0;
    border-top: 0;
    margin-bottom: 0;
    width: var(--hiburim-s-menuWidth);
    background-color: var(--hiburim-menu);
  }
}

@media (max-width: 1024px) {
  .navbar {
    display: flex;
    border-top: 0;
    width: var(--hiburim-s-menuWidth);
    background-color: var(--hiburim-menu);
    align-items: right;
    padding: 0;
    border-top: "1px solid #e0e0e0";
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1000;
  }
}
